/* global Foundation */

$(document).ready(function () {
    'use strict';

    let currentMedia = Foundation.MediaQuery.current;
    let yOffset = window.pageYOffset;
    const $stickyContainer = $('.js-sticky-container');

    const offsetCheck = function () {
        const $nav = $('.side-navigation');
        const stickyNaviOffset = $stickyContainer.offset().top;

        if (window.pageYOffset > yOffset && window.pageYOffset > stickyNaviOffset) {
            $nav.addClass('is-stuck');
            $nav.removeClass('is-anchored');
        } else {
            $nav.removeClass('is-stuck');
            $nav.addClass('is-anchored');
        }

        yOffset = window.pageYOffset;
    };

    const navigationEvent = function () {
        if ($stickyContainer.length && currentMedia === 'small') {

            $('body').on('scrollme.zf.trigger', offsetCheck);
        } else {
            $('body').off('scrollme.zf.trigger', offsetCheck);
        }
    };

    $(window).on('changed.zf.mediaquery', function (event, newSize) {
        currentMedia = newSize;
        navigationEvent();
    });

    navigationEvent();
});
