/* eslint no-underscore-dangle: 0 */
/* eslint no-console: 0 */
let Congstar;

(function ($) {
  'use strict';

  Congstar = {
    submitSuccess: false,
    reveal: $('#reveal-congstar'),

    init: function () {
      function createCookie(name, value, days) {
        var expires;
        if (days) {
          var date = new Date();
          date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
          expires = '; expires=' + date.toGMTString();
        } else {
          expires = '';
        }
        document.cookie = name + '=' + value + expires + '; path=/';
      }

      function readCookie(name) {
        var nameEQ = name + '=';
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
          var c = ca[i];
          while (c.charAt(0) === ' ') {
            c = c.substring(1, c.length);
          }
          if (c.indexOf(nameEQ) === 0) {
            return c.substring(nameEQ.length, c.length);
          }
        }
        return null;
      }

      function convertFormToJSON(form) {
        var array = $(form).serializeArray();
        var json = {};
        $.each(array, function () {
          json[this.name] = this.value || '';
        });
        return json;
      }

      const params = new window.URLSearchParams(window.location.search);

      if (this.reveal.length && !localStorage.getItem('nfxCongstarValidCode')) {
        this.reveal.foundation('open');

        $(document).on('closed.zf.reveal', '#reveal-congstar', function (e) {
          console.log(e);
        });

      }

      if (localStorage.getItem('nfxCongstarValidCode')) {
        $('body').addClass('code-is-valid');
      }

      // Submit form
      $('#congstarForm').on('submit', function (e) {
        e.preventDefault();
        $(this).hide();
        const cockpitToken = '6f4fce5652478449ad91488141f9f6';
        let successMessage = $(this).parent().find('.form-success').first();
        let errorMessage = $(this).parent().find('.form-error').first();
        let loader = $(this).parent().find('.form-loader').first();
        let form = $(e.target);
        loader.show();

        let data = convertFormToJSON(form);
        let encodedData = '{"data" :' + JSON.stringify(data) + '}';
        let action = form.attr('action');

        function submitError() {
          loader.hide();
          errorMessage.show();
          successMessage.hide();
        }

        function updateCode(codeId, uses) {
          console.log(codeId);
          $.ajax({
            url: 'https://dkb-tmp-preview.nfxhosting.de/cockpit/api/collections/save/congstarCodes',
            method: 'POST',
            headers: {
              'Cockpit-Token': cockpitToken
            },
            contentType: 'application/json',
            dataType: 'json',
            data: JSON.stringify({
              data: {
                '_id': codeId,
                'uses': String(uses),
              }
            }),
            success: function (xhr) {
              loader.hide();
              successMessage.show();
              errorMessage.hide();
              $('body').addClass('code-is-valid');
              localStorage.setItem('nfxCongstarValidCode', btoa(String(Math.round(+new Date() / 1000))));
              Congstar.submitSuccess = true;
              window.setTimeout(function() {
                Congstar.reveal.foundation('close');
              }, 5000);
            },
            error: function (xhr, status, error) {
              submitError();
              console.log(xhr);
            },
          });
        }

        function checkCode(code) {
          $.ajax({
            url: 'https://dkb-tmp-preview.nfxhosting.de/cockpit/api/collections/get/congstarCodes?token=6f4fce5652478449ad91488141f9f6&filter[code]=' + code,
            method: 'GET',
            success: function (xhr) {
              if (xhr.entries.length) { // Code is valid
                let codeData = xhr.entries[0];
                let codeId = codeData._id;
                let uses = codeData.uses;
                if (uses === '') {
                  uses = 0;
                }
                else {
                  uses = parseInt(codeData.uses);
                }

                if (uses === 0) {
                  updateCode(codeId, uses + 1);
                }
                else {
                  submitError();
                }
              } else {
                submitError();
              }
            },
            error: function (xhr, status, error) {
              submitError();
              console.log(xhr);
            },
          });
        }

        if (!data.a_password) { //Prevent spam
          checkCode(data.code.toUpperCase());
        } else {
          console.log('Go away spammy bot!');
          return false;
        }
        return false;
      });
    },
  };

  $(document).ready(function () {
    Congstar.init();
  });
})(jQuery);
