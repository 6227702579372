/* eslint no-console: 0 */
let ExitIntent;
window.dataLayer = window.dataLayer || [];

(function ($) {
  'use strict';

  ExitIntent = {
    submitSuccess: false,
    init: function () {
      var myScroll = (function () { //Function that checks the speed of scrolling
        var lastPosition, newPosition, timer, delta, delay = 50;

        function clear() {
          lastPosition = null;
          delta = 0;
        }

        clear();
        return function () {
          newPosition = window.scrollY;
          if (lastPosition !== null) {
            delta = newPosition - lastPosition;
          }
          lastPosition = newPosition;
          clearTimeout(timer);
          timer = setTimeout(clear, delay);
          return delta;
        };
      })();

      function createCookie(name, value, days) {
        var expires;
        if (days) {
          var date = new Date();
          date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
          expires = '; expires=' + date.toGMTString();
        } else {
          expires = '';
        }
        document.cookie = name + '=' + value + expires + '; path=/';
      }

      function readCookie(name) {
        var nameEQ = name + '=';
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
          var c = ca[i];
          while (c.charAt(0) === ' ') {
            c = c.substring(1, c.length);
          }
          if (c.indexOf(nameEQ) === 0) {
            return c.substring(nameEQ.length, c.length);
          }
        }
        return null;
      }

      function exitIntent() {
        if (!readCookie('nfxExitIntentShown') && !localStorage.getItem('nfxExitIntentShown') && $('#reveal-exitintent').length && !$('body').hasClass('is-reveal-open') && !$('body').hasClass('tc-modal-open')) {
          window.dataLayer.push({
            'event': 'popup_exit_intent',
            'environment_data': {
              'site_environment': window.siteEnvironment || 'prod'
            }
          });
          $('#reveal-exitintent').foundation('open');
          localStorage.setItem('nfxExitIntentShown', '1');
          createCookie('nfxExitIntentShown', 1);
        }
      }

      function myScrollSpeedFunction() {
        if (myScroll() < -40) {
          exitIntent();
        }
      }

      // Convert form data to JSON
      function convertFormToJSON(form) {
        var array = $(form).serializeArray();
        var json = {};
        $.each(array, function () {
          json[this.name] = this.value || '';
        });
        return json;
      }

      $(document).on('touchstart', function () {
        $('body').addClass('on-mobile-device');
      });

      document.addEventListener('consentReady', function() {
        window.setTimeout(function () {
          if (window.matchMedia('(pointer: coarse)').matches) { //touch devices
            $(document).on('scroll', myScrollSpeedFunction);
          } else {
            $(document).on('mouseleave', function () {
              exitIntent();
            });
          }
        }, 15000);
      });

      $(document).on('closed.zf.reveal', '#reveal-exitintent', function () {
        if (!ExitIntent.submitSuccess) {
          window.dataLayer.push({
            'event': 'close_exit_intent',
            'environment_data': {
              'site_environment': window.siteEnvironment || 'prod'
            }
          });
        }
      });

      // Show DOI Thanks
      const params = new window.URLSearchParams(window.location.search);

      if (!readCookie('nfxExitIntentDOIShown') && params.get('doi-thanks') && $('#reveal-exitintent-doi').length) {
        $('#reveal-exitintent-doi').foundation('open');
        createCookie('nfxExitIntentDOIShown', 1);
        window.setTimeout(function () {
          $('#reveal-exitintent-doi').foundation('close');
        }, 15000);
      }

      // Submit form
      $('#exitIntentForm').on('submit', function (e) {
        e.preventDefault();
        $(this).hide();
        let successMessage = $(this).parent().find('.form-success').first();
        let errorMessage = $(this).parent().find('.form-error').first();
        let loader = $(this).parent().find('.form-loader').first();
        let form = $(e.target);
        loader.show();
        let data = convertFormToJSON(form);
        let encodedData = '{"data" :' + JSON.stringify(data) + '}';
        let action = form.attr('action');
        if (!data.a_password) { //Prevent spam
          $.ajax({
            url: action,
            headers: {
              'Cockpit-Token': 'c10d5973199bcb9cc8fd9cdc6d28d7'
            },
            method: 'POST',
            data: encodedData,
            contentType: 'application/json',
            dataType: 'json',
            success: function () {
              loader.hide();
              successMessage.show();
              errorMessage.hide();
              ExitIntent.submitSuccess = true;
              window.dataLayer.push({
                'event': 'submit_exit_intent',
                'environment_data': {
                  'site_environment': window.siteEnvironment || 'prod'
                }
              });
            },
            error: function (xhr, status, error) {
              loader.hide();
              errorMessage.show();
              successMessage.hide();
              console.log(xhr);
            },
          });
        } else {
          console.log('Go away spammy bot!');
          return false;
        }
        return false;
      });
    },
  };
})(jQuery);
