(function ($) {
  'use strict';

  const Reveal = {
    init: function () {


      $(document).on('closed.zf.reveal', '[data-reveal]', function () {
        $('.youtube-video').remove('iframe');
        $('.js-lazyYT').lazyYT();
        $('.js-lazyYT').removeClass('lazyYT-video-loaded');
        $('html').removeClass('is-reveal-open');
      });

      $(document).on('open.zf.reveal', '[data-reveal]', function () {
        $('html').addClass('is-reveal-open');
        $('.js-lazyYT').lazyYT();
        $('.slick-slider.slick-initialized').slick('refresh');

        var $this = $(this);
        window.setTimeout(function(that) {
          //$this.find('.lazyYT-image-loaded').click();
        }, 600);
      });
    }
  };

  Reveal.init();
})(jQuery);
