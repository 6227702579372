var userHasScrolled = false;
var myScrollDirection = (function () { //Function that checks the speed of scrolling
  var lastPosition, newPosition, timer, delta, delay = 50;

  function clear() {
    lastPosition = null;
    delta = 0;
  }

  clear();
  return function () {
    newPosition = window.scrollY;
    if (lastPosition !== null) {
      delta = newPosition - lastPosition;
    }
    lastPosition = newPosition;
    clearTimeout(timer);
    timer = setTimeout(clear, delay);
    return delta;
  };
})();
window.caReady = window.caReady || []; //CommandersAct API
window.cact = function() { window.caReady.push(arguments); }; //CommandersAct API
var consentReadyEvent = new CustomEvent('consentReady', { detail: { message: 'Consent is ready.', time: new Date(), }});
var consentRefusedEvent = new CustomEvent('consentRefused', { detail: { message: 'Consent was refused.', time: new Date(), }});
var consentGivenEvent = new CustomEvent('consentGiven', { detail: { message: 'Consent was given.', time: new Date(), }});

cact('consent.onReady', function (result) {
  document.dispatchEvent(consentReadyEvent);
  if (result.consent.status !== 'all-off') {
    document.dispatchEvent(consentRefusedEvent);
  }
  else {
    document.dispatchEvent(consentGivenEvent);
  }
});

window.addEventListener('scroll', function () {
  userHasScrolled = true;
  let delta = myScrollDirection();

  if (delta > 10) {
    $('body').removeClass('scrolling-up').addClass('scrolling-down');
  }

  if (delta < -10) {
    $('body').removeClass('scrolling-down').addClass('scrolling-up');
  }
});

$(document).ready(function () {
  const body = $('body');
  const header = $('header');
  const headerHeight = header.length ? header.height() : 0;
  const menuTop = $('.menu-top');
  const menuTopHeight = menuTop.length ? menuTop.height() : 0;
  const headerOffset = headerHeight + menuTopHeight;
  const topBar = $('.top-bar');
  const target = $('#badges');
  const video = $('video.pause-on-offside');

  $(document).foundation();

  localStorage.removeItem('nfxExitIntentShown');

  if (document.location.href.indexOf('wt_mc=npk.email.gk_exitintent') === -1) {
    ExitIntent.init();
  }

  $('.tabs').on('change.zf.tabs', function (event, tab) {
    $('.tabs-panel .animate-svg svg').removeClass('can-start-animation animate-now');
    $('.tabs-panel .onvisible-fade-up, .tabs-panel .onvisible-slide-left, .tabs-panel .onvisible-slide-right').removeClass('onvisible-active');
    $('.slick-slider.slick-initialized').slick('refresh');
    $('.slick-slider-badges.slick-initialized').slick('refresh');

    window.setTimeout(function () {
      $('.tabs-panel.is-active .animate-svg svg').addClass('can-start-animation animate-now is-visible');
      $('.tabs-panel .onvisible-fade-up, .tabs-panel .onvisible-slide-left, .tabs-panel .onvisible-slide-right').addClass('onvisible-active');
    }, 500);
  });

  $(window).on('changed.zf.mediaquery', function (event, newSize, oldSize) {
    //$('.slick-slider.slick-initialized').slick('refresh');
    //$('.slick-slider-badges.slick-initialized').slick('refresh');
    window.setTimeout(function () {
      $('.slick-slider.slick-initialized').slick('unslick');
      Slider.init();
    }, 100);

  });

  $('.js-sticky-badge-small').on('click', function () {
    'use strict';

    $('.js-sticky-badges').removeClass('is-closed');
    $('.js-sticky-badges').addClass('is-open');

  });

  body.on('scrollme.zf.trigger', function () {
    if (video.length && window.pageYOffset > video.height() * 2) {
      video[0].pause();
    } else {
      if (video.length) {
        video[0].play();
      }
    }

    var scrolledDownOffset = 600; //parseInt(headerOffset) + ($(window).height() / 3)
    if (window.pageYOffset > scrolledDownOffset) {
      body.addClass('scrolled-down');
      if (target.length || body.hasClass('payday')) {
        topBar.addClass('stuck');
      }
    }
    else {
      body.removeClass('scrolled-down scrolling-up');
      topBar.removeClass('stuck');
    }
  });

  // show or hide "Konto wechseln" Sticky depending on scroll position

  $(window).on('resize scroll', function () {
    let windowHeight = window.outerHeight;
    let switchAccountBox = document.querySelector('.section-switch-account-full-width');
    let hero = document.querySelector('.header-subpage');
    if (switchAccountBox && hero) {
      let switchAccountBoxTopPos = switchAccountBox.getBoundingClientRect().top;
      let heroBottomPos = hero.getBoundingClientRect().bottom;

      if (windowHeight - switchAccountBoxTopPos >= 250) {
        body.addClass('scrolledToSwitchAccountBox');
      } else if (windowHeight - switchAccountBoxTopPos < 20) {
        body.removeClass('scrolledToSwitchAccountBox');
      }
      if (heroBottomPos <= -100) {
        body.addClass('scrolledBeyondHero');
      } else if (heroBottomPos > -100) {
        body.removeClass('scrolledBeyondHero');
      }
    }
  });


  $('.js-sticky-badge-large').on('click', function () {
    'use strict';

    $('.js-sticky-badges').removeClass('is-open');
    $('.js-sticky-badges').addClass('is-closed');

  });

    Slider.init();


  if (window.location.hash) {
    const scrollTarget = $(window.location.hash.replace('scroll-', ''));

    if (scrollTarget.length && !userHasScrolled) {
      if (window.location.hash.substr(0, 8) === '#reveal-') {
        location.hash = '';
        window.setTimeout(function () {
          $(scrollTarget).foundation('open');
        }, 500);
      } else {
        location.hash = '';
        window.setTimeout(function () {
          $('html, body').animate({
            scrollTop: (scrollTarget.offset().top - headerHeight - 50) + 'px'
          }, 500, 'swing');
        }, 500);
      }
    }
  }

  $(window).one('touchstart', function () {
    $(window).on('scroll ', function () {
      $('.hover').parent().removeClass('hover-effect');
    });
    $('.hover').one('click', function (e) {
      $(this).parent().addClass('hover-effect');
      e.preventDefault();
    });
  });

  $('a[href^="#"]:not([href="#"])').click(function () {
    const $this = $(this);
    if ($this.attr('role') === 'tab') {
      return true;
    }

    let t = $(this.hash);
    t = t.length ? t : $('[name=' + this.hash.slice(1) + ']');
    if (t.length) {
      $('html, body').animate({
        scrollTop: t.offset().top - headerHeight - 20
      }, 1000, 'swing');
    }

    return false;
  });

  if ((document.location.href.indexOf('geldverbesser') === -1 && !body.hasClass('kampagne')) || body.hasClass('subpage')) {
    window.setTimeout(function () {
      $(window).trigger('scroll');
    }, 500);
  }

  if (body.hasClass('subpage')) {
    window.setTimeout(function () {
      $('body, html').addClass('scroll-start');
      $('.scale').addClass('is-scaled');
    }, 500);
  }

  $('form [type="checkbox"]:checked').parent().addClass('is-checked');
  $('form [type="checkbox"]').on('click', function () {
    $(this).parent().toggleClass('is-checked');
  });

  $('form label a').on('click', function (e) {
    e.preventDefault();
  });

  $('.kampagne-gv-lead-sale .hero-content .button').clone().removeClass('show-for-large').addClass('hide-for-large').appendTo('.hero-overlay ._form ._button-wrapper');

  $('#hide-conditons')
    .hide()
    .bind('click', function () {
      $('#conditions').removeClass('open');
      $(this).hide();
      $('#show-conditons').show();
      //$('.infotext-box').show();
    });

  $('#show-conditons')
    .bind('click', function () {
      $('#conditions').addClass('open');
      $(this).hide();
      $('#hide-conditons').show();
      //$('.infotext-box').hide();
    });

  $('#toggleButton')
    .bind('click', function() {
      if ($(this).hasClass('expanded')) {
        $(this).find('span').text($(this).attr('data-toggle-default-text'));
      }
      else {
        $(this).find('span').text($(this).attr('data-toggle-expanded-text'));
      }
    });

  window.addEventListener('nfxCalculatorButtonsUpdate', function () {
    $('.nfx-calculator-button[data-type]').each(function () {
      $(this).attr('href', $(this).attr('href').replace('type=31', 'type=XXX'));
      $(this).attr('href', $(this).attr('href').replace('type=3', 'type=XXX'));
      $(this).attr('href', $(this).attr('href').replace('type=XXX', 'type=' + $(this).attr('data-type')));
    });
  });

});

//feature comparison

class Box {
  constructor(box, siblingIds) {
      this.box = box;
      this.siblingIds = siblingIds
      this.siblingBoxes = null
      this.showMoreState = true;
      this.showMoreButton = box.querySelector(".js-show-more");
      this.showLessButton = box.querySelector(".js-show-less");
  }
  initFeatureBox() {
      if (this.showMoreButton && this.showLessButton) {
          if (this.siblingIds.length) {
            this.siblingBoxes = this.siblingIds.map(id => {
              return document.querySelector(`#${id}`)
             });
          }
          this.liAmount = this.box.dataset.showFeatures;
          this.lis = this.box.querySelectorAll(`.feature`);
          this.showMoreButton.addEventListener("click", () => {
              this.showLis();
              if(this.siblingBoxes.length) {
                this.showSiblingLis()
              }      
          });
          this.showLessButton.addEventListener("click", () => {
              this.hideLis();
              if(this.siblingBoxes.length) {
                this.hideSiblingLis()
              }
          }); 
          this.hideLis()  
         
      }
  }

  hideLis() {
      this.lis.forEach((li, i) => {
          if (i >= this.liAmount) {
              li.classList.add("is-hidden");
          }
      });
      this.showLessButton.classList.add("is-hidden");
      this.showMoreButton.classList.remove("is-hidden");
  }

  hideSiblingLis() {
      if (this.siblingBoxes.length) {
        this.siblingBoxes.forEach(siblingBox => {
          if (!siblingBox) return
          const lis = siblingBox.querySelectorAll(`.feature`);
          const liAmount = siblingBox.dataset.showFeatures;
          const showMoreButton = siblingBox.querySelector(".js-show-more");
          const showLessButton = siblingBox.querySelector(".js-show-less");
          lis.forEach((li, i) => {
              if (i >= liAmount) {
                  li.classList.add("is-hidden");
              }
          });
          showLessButton.classList.add("is-hidden");
          showMoreButton.classList.remove("is-hidden");
        })
          
      }
  }
  

  showLis() {
      
      this.lis.forEach((li) => {
          li.classList.remove("is-hidden");
      });
      this.showMoreButton.classList.add("is-hidden");
      this.showLessButton.classList.remove("is-hidden");
  }

  showSiblingLis() {
    if (this.siblingBoxes.length) {
      this.siblingBoxes.forEach(siblingBox => {
        if (!siblingBox) return
        const lis = siblingBox.querySelectorAll(`.feature`);
        const showMoreButton = siblingBox.querySelector(".js-show-more");
        const showLessButton = siblingBox.querySelector(".js-show-less");
        lis.forEach((li) => {
                li.classList.remove("is-hidden");
        });
        showMoreButton.classList.add("is-hidden");
        showLessButton.classList.remove("is-hidden");
      })
        
    }
}    

}


//init feature boxes
  
  const featureBoxes = document.querySelectorAll('.js-feature-box')
  if (featureBoxes.length) {
    featureBoxes.forEach(box => {
      let siblingIds = [];
      if (box.dataset.siblingIds) {
        siblingIds = box.dataset.siblingIds.split(',');
      }
     const boxInstance =  new Box(box, siblingIds)
     boxInstance.initFeatureBox()
    })
  }