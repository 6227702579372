(function ($) {
  'use strict';

  const Animation = {
    scrollActive: false,

    init: function () {
      Animation.initOnLoadFunctions();
      Animation.initOnVisibleFunctions();
    },

    initOnLoadFunctions: function () {
      $('h1:has(strong), h2:has(strong), .heading-1:has(strong), .heading-2:has(strong)').addClass('mixed-headline');
      window.setTimeout(function() {
        $('body').addClass('animation-ready');
      }, 750);
    },

    initOnVisibleFunctions: function () {
      Animation.onScroll(true);

      $(window).on('scroll touchmove', function (event) {
        if ((document.location.href.indexOf('geldverbesserer') >= 0 || $('body').hasClass('kampagne')) && document.location.href.indexOf('page') === -1 && event.target.nodeName !== 'svg' && !$('body').hasClass('overlay-loaded') && window.innerWidth > window.innerHeight) {
          $('body, html').addClass('scroll-start');
          $('.scale').addClass('is-scaled');
        } else {
          $('body, html').removeClass('no-scroll');
        }

        if (Animation.scrollActive) {
          return;
        }

        Animation.scrollActive = true;
        requestAnimationFrame(() => {
          Animation.onScroll(false, event);
        });
      });
    },

    onScroll: function (initial = false, event) {
      $('[class*="onvisible-"], .onscroll-activate').each(function (idx, el) {
        const $element = $(el);
        if ($element.hasClass('onvisible-active')) {
          return;
        }

        if ($element.hasClass('onscroll-activate')) {
          if (!$element.hasClass('onscroll-active') && !initial && event.target.nodeName !== 'svg') {
            $element.addClass('onscroll-active');

            setTimeout(() => {
              $('body, html').removeClass('no-scroll');
              $('body').addClass('overlay-loaded');
            }, 200);
          }

          return;
        }

        const
          elementPosition = $element.offset().top,
          windowBottom = $(window).scrollTop() + $(window).height() + 100;

        if (windowBottom > elementPosition) {
          setTimeout(function () {
            $element.addClass('onvisible-active');
          }, 100);
        }
      });

      Animation.scrollActive = false;
    }
  };

  Animation.init();
})(jQuery);
