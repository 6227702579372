(function ($) {
  'use strict';

  const SvgAnimation = {

    init: function () {
      $('.reveal .animate-svg').removeClass('animate-svg');
      $('.animate-svg svg, .animate-scroll, .animate-scroll-zoom, .image-over-text.new').attr('data-scroll-class', 'animate-now').scrollClass({
        offsetTop: 100,
        delay: 10,
        threshold: 100,
        callback: function callback() {
          var el = $(this);
          var delay = 200;
          if (el.parent().attr('data-stagger') > 0) {
            delay = el.parent().attr('data-stagger') * 100;
          }
          window.setTimeout(function () {
            el.addClass('can-start-animation');
          }, delay);
        }
      }).trigger('scroll');
    },

  };

  SvgAnimation.init();
})(jQuery);
