class Analytics {
    constructor(callback) {
        this.track = callback;
        this.customEvents = [];
        this.config = {
            buttonSelector: '.button,.cta,button',
            linkSelector: '.link,a',
        };
    }

    init() {
        this.registerClosestPolyfill();
        this.bindCustomEventHandler();
        this.bindDefaultEventHandler();
    }

    addCustomEvent(container, selector, category, action, label = false, useTextContentForAction = false, labelIsSelector = false) {
        this.customEvents.push({
            container, selector, category, action, label, labelIsSelector, useTextContentForAction
        });
    }

    handleDefaultEvents(event) {
        let element;

        if (event.target.matches('span') && event.target.closest('.close-button')) {
            element = event.target.closest('.close-button');
        } else if (event.target.matches('span') && event.target.closest('a')) {
            element = event.target.closest('a');
        } else if (event.target.matches('img') && event.target.closest('a')) {
            element = event.target.closest('a');
        } else if (event.target.matches('div') && event.target.closest('a')) {
            element = event.target.closest('a');
        } else if (event.target.matches('p') && event.target.closest('a')) {
            element = event.target.closest('a');
        } else if (event.target.matches('h2') && event.target.closest('a')) {
            element = event.target.closest('a');
        } else if (event.target.matches('svg') && event.target.closest('a')) {
            element = event.target.closest('a');
        } else if (event.target.matches('h5') && event.target.closest('a')) {
            element = event.target.closest('a');
        } else {
            element = event.target;
        }

        const eventObject = {
            event: 'event',
        };

        if (element.matches('.menu-item a') || element.matches('.navbar-link')) {
            eventObject.eventCategory = 'Navigation';
            eventObject.eventAction = element.innerText;
            eventObject.eventLabel = element.getAttribute('href') || '';
        } else if (element.matches('span') && element.closest('.close-button')) {
            eventObject.eventCategory = 'Product-Teaser';
            eventObject.eventAction = element.querySelector('.button').innerText || '';
            eventObject.eventLabel = element.querySelector('h5').innerText || '';
        } else if (element.matches('a.product-teaser')) {
            eventObject.eventCategory = 'Product-Teaser';
            eventObject.eventAction = element.querySelector('.button').innerText || '';
            eventObject.eventLabel = element.querySelector('h5').innerText || '';
        } else if (element.matches('[data-open]')) {
            eventObject.eventCategory = 'Modal';
            eventObject.eventAction = element.dataset.open.replace('reveal-', '') || '';
            eventObject.eventLabel = 'Open';
        } else if (element.matches('.close-button')) {
            eventObject.eventCategory = 'Modal';
            eventObject.eventAction = element.closest('.reveal').id.replace('reveal-', '') || '';
            eventObject.eventLabel = 'Close';
        } else if (element.matches('a.content')) {
            eventObject.eventCategory = 'Interner Link';
            eventObject.eventAction = element.querySelector('h2').innerText || '';
            eventObject.eventLabel = element.href;
        } else if (element.classList.contains('accordion-title')) {
            eventObject.eventCategory = 'Accordion';
            eventObject.eventAction = element.innerText;
            eventObject.eventLabel = 'Toggle';
        } else if (element.hasAttribute('href') && element.getAttribute('href').indexOf('tel:') >= 0) {
            eventObject.eventCategory = 'Call to Action';
            eventObject.eventAction = 'Kontaktaufnahme';
            eventObject.eventLabel = 'Telefon';
        } else if (element.hasAttribute('href') && element.getAttribute('href').indexOf('mailto:') >= 0) {
            eventObject.eventCategory = 'Call to Action';
            eventObject.eventAction = 'Kontaktaufnahme';
            eventObject.eventLabel = 'E-Mail';
        } else if (element.matches(this.config.buttonSelector)) {
            eventObject.eventCategory = 'Button';
            eventObject.eventAction = element.innerText;
            eventObject.eventLabel = element.getAttribute('href') || '';
        } else if (element.matches(this.config.linkSelector)) {
            const href = element.href || '';

            if (href.indexOf(document.location.host) >= 0) {
                eventObject.eventCategory = 'Interner Link';
            } else {
                eventObject.eventCategory = 'Externer Link';
            }

            eventObject.eventAction = element.innerText;
            eventObject.eventLabel = element.getAttribute('href') || '';
        }

        if (eventObject.hasOwnProperty('eventCategory')) {
            // look for section
            const sections = document.querySelectorAll('section[id]');
            const section = element.closest('section[id]');

            if (section) {
                const sectionIndex = [...sections].indexOf(section);
                const sectionId = section.id || '';

                eventObject.eventCategory = '#' + sectionId + '_' + sectionIndex + '_' + eventObject.eventCategory;
            }

            this.track(eventObject);
        }
    }

    handleDynamicEvents(event) {
        const element = event.target;

        if (element.classList.contains('js-ga')) {
            const eventObject = {
                event: 'event',
                eventCategory: element.dataset.eventCategory || '',
                eventAction: element.dataset.eventAction || '',
                eventLabel: element.dataset.eventLabel || document.location.href
            };

            this.track(eventObject);
        }
    }

    bindDefaultEventHandler() {
        window.addEventListener('click', this.handleDefaultEvents.bind(this));
        window.addEventListener('click', this.handleDynamicEvents.bind(this));
    }

    bindCustomEventHandler() {
        for (let i = 0; i < this.customEvents.length; i++) {
            let currentEvent = this.customEvents[i];
            if (currentEvent.container) {
                const container = document.querySelectorAll(currentEvent.container);
                const self = this;

                try {
                    container.forEach((current) => {
                        current.addEventListener('click', function (event) {
                            const element = event.target;
                            const eventObject = {
                                event: 'event',
                                eventCategory: currentEvent.category,
                            };

                            if (element.matches(currentEvent.selector)) {
                                eventObject.eventAction = (currentEvent.useTextContentForAction
                                    ? element.textContent
                                    : eventObject.action);

                                if (currentEvent.label) {
                                    eventObject.eventLabel = (currentEvent.labelIsSelector
                                        ? current.querySelector(currentEvent.label).textContent
                                        : currentEvent.label);
                                }

                                self.track(eventObject);
                            }
                        });
                    });
                } catch (e) {
                    // element doesn't exist on current page
                }
            }
        }
    }

    registerClosestPolyfill() {
        if (window.Element && !Element.prototype.closest) {
            Element.prototype.closest =
                function (s) {
                    let matches = (this.document || this.ownerDocument).querySelectorAll(s),
                        i,
                        el = this;
                    do {
                        i = matches.length;
                        while (--i >= 0 && matches.item(i) !== el) {
                        } // eslint-disable-line no-empty
                    } while ((i < 0) && (el = el.parentElement));
                    return el;
                };
        }
    }
}

const analytics = new Analytics(function (event) {
    if (typeof window.tC.event !== 'undefined') {
        tC.event.nfx_events(this, {
            'event_category': event.eventCategory,
            'event_action': event.eventAction,
            'event_label': event.eventLabel
        });
    }
});

analytics.init();
