let Slider;

(function ($) {
  'use strict';

  Slider = {
    init: function () {

      $('.slick-slider-badges:not(.slick-initialized)').slick({
        dots: false,
        infinite: true,
        arrows: false,
        speed: 300,
        autoplay: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        mobileFirst: true,
        responsive: [{
            breakpoint: 480,
            settings: 'unslick'
          }]
      });

     
      let productTilesCount = $('#product-slider .product-tiles .tile').length;
      if (productTilesCount) {
        let maxSlides = 5;
        if (productTilesCount < 15) {
          maxSlides = Math.max(2, Math.min(4, Math.floor(productTilesCount / 2)));
        }
        let productTilesWidth = window.innerWidth - $('#product-slider .product-tiles').offset().left;
        $('#product-slider .product-tiles').css('width', productTilesWidth + 'px').slick({
          dots: false,
          arrows: true,
          infinite: false,
          speed: 300,
          slidesToShow: Math.min(Math.max(Math.floor(window.innerWidth / 300), 1), maxSlides),
          slidesToScroll: 3,
          variableWidth: false,
          rows: 2,
          //appendArrows: $('#product-slider .slide-row'),
          responsive: [
            {
              breakpoint: 820,
              settings: {
                arrows: false,
                slidesToShow: 3,
                rows: 1
              }
            },
            {
              breakpoint: 480,
              settings: {
                variableWidth: false,
                dots: false,
                rows: 1,
                arrows: false,
                centerMode: true,
                centerPadding: '20px',
                slidesToShow: 1
              }
            }
          ]
        });
      }

      
       
         $('#highlight-slider .product-tiles').slick({
          dots: false,
          arrows: true,
          infinite: false,
          speed: 300,
          slidesToShow: 6,
          slidesToScroll: 3,
          variableWidth: false,
          //appendArrows: $('#product-slider .slide-row'),
          responsive: [
            {
              breakpoint: 1240,
              settings: {
                arrows: false,
                slidesToShow: 4.5,
              }
            },
            {
              breakpoint: 600,
              settings: {
                arrows: false,
                slidesToShow: 2.5,
                slidesToScroll: 2,
              }
            },
            {
              breakpoint: 410,
              settings: {
                arrows: false,
                slidesToShow: 1.5,
                slidesToScroll: 1,
              }
            }
          ]
        }); 
      

      $('.slick-slider:not(.slick-initialized)')
        .slick()
        .on('afterChange', function (event, slick, currentSlide, nextSlide) {
          $(slick.$list[0]).find('.slick-active .animate-now').addClass('is-visible');

          window.setTimeout(function() {
            $(slick.$list[0]).find('.slick-slide:not(.slick-active) .animate-now').removeClass('is-visible');
          }, 500);
        });

      $('.slick-slider .slick-active svg').addClass('is-visible');

      
       $('.feature-slider').slick({
        slidesToShow: 2,
        infinite: false,
        centerPadding: '20px',
        dots: false,
         responsive: [
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1.5,
              dots: true,
            }
          },
          {
            breakpoint: 390,
            settings: {
              slidesToShow: 1.2,
              dots: true,
            }
          },
          
        ]

      })
      //stellt sicher dass alle features die selbe höhe haben
      $('.feature-slider').init(() => {
      const featureBox = document.querySelector('.js-feature-box');
      if (! featureBox) return
      const siblingid = featureBox.dataset.siblingIds.split(',')[0];
      if (!siblingid) return
      document.querySelector('.js-show-more').click()
      //aktuell nur auf eine siblingbox ausgelegt
      const siblingBox = document.querySelector(`#${siblingid}`);
      const features = featureBox.querySelectorAll('[data-feature-id]');
      features.forEach((feature) => {
      const featureId = feature.dataset.featureId
      if (featureId) {
        const siblingFeature = siblingBox.querySelector(`[data-feature-id="${featureId}"]`);
        const height = Math.max(feature.offsetHeight, siblingFeature.offsetHeight);
        feature.style.height = `${height}px`;
        siblingFeature.style.height = `${height}px`;
      }
    })
    document.querySelector('.js-show-less').click()
      })
    }
  };

})(jQuery);

